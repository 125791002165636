/* REACT PACKAGE IMPORTS */
import { useRef, useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

/* INTERNAL IMPORTS */
import { forgotUsername } from "../Features/Auth/Actions/AuthActions";
import { openModal } from "../Features/Errors/Slices/errorSlice";
import ForgotUsernameIcon from "../assets/authentication/forgotUsernameIcon.png";
import "../styles/Login.css";


export default function ForgotUsername({ logo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailInput, setEmailInput] = useState("");

  const emailRef = useRef();

  useEffect(() => emailRef.current.focus(), []);
  useEffect(() => removeValidationClasses(), [emailInput]);

  const removeValidationClasses = () => {
    emailRef.current.classList.remove('is-valid');
    emailRef.current.classList.remove('is-invalid');
  };

  const validateInput = () => {
    const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailIsValid = emailRegEx.test(emailInput);

    let validationErrorMessage = "";
    let inputIsValid = true;

    // Case: Email field filled
    // Case: Email field empty
    if (!emailIsValid || emailInput === "") {
      validationErrorMessage = `Please enter a valid email address e.g. user@victvs.co.uk`;
      emailRef.current.classList.add('is-invalid');
      inputIsValid = false;
    };

    // Return input validity
    if (inputIsValid) {
      return true;
    } else {
      dispatch(
        openModal({
          message: validationErrorMessage,
          severity: "warning",
        })
      );
      return false;
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create suitable request data object if input is valid
    if (validateInput()) {
      let requestObject = { email: emailInput };
      emailRef.current.classList.add('is-valid');
      dispatch(forgotUsername(requestObject))
        .then(response => {
          if(response.error) return; 
          setTimeout(() => navigate("/login"), 2500);
        });;
    }
  };

  return (
    <main className="Login position-relative">
      <div className="Login-container box position-absolute top-50 start-50 translate-middle p-4">

        <div className="d-flex flex-column mx-5 my-3 justify-content-between align-items-center">
          {/* Invisible h1 added for accessibility requirements */}
          <h1 className="visually-hidden">victvs vx forgot username</h1>
          <div className="mb-2">
            <img src={logo} alt="Logo" className="h-32" />
          </div>
          <p className="h6 my-2 text-slate-700">Forgot Your Username?</p>
          <div className="text-center">
            <Form.Text>
              Please enter your email address below
              and we will send your VX username to your email.
            </Form.Text>
          </div>
        </div>

        <Form
          id="form"
          className="mx-5 my-3"
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-4">

            <Form.Label id="email-label">Email</Form.Label>
            <Form.Control
              data-cy="emailField"
              ref={emailRef}
              aria-labelledby="email-label"
              name="email"
              type="email"
              value={emailInput}
              onInput={event => setEmailInput(event.target.value)}
            />
          </Form.Group>
          <Button
            data-cy="submitButton"
            variant="dark"
            type="submit"
            className="mt-3 mb-4 container"
          >
            Submit
          </Button>
          <div className="d-flex flex-column">
            <Form.Text>
              Back to Sign In page?
              <Link
                className="mx-2 text-decoration-none link-font-size"
                onClick={() => navigate(-1)}
              >
                Sign In
              </Link>
            </Form.Text>
          </div>
        </Form>
      </div>
    </main>
  );
}
