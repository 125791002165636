/* REACT PACKAGE IMPORTS */
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

/* INTERNAL IMPORTS */
// Components
import NavBar from "./NavBar";
import IdleTimeOutModal from "./IdleTimeOutModal";
import { endWalkthrough } from '../Features/Walkthroughs/Actions/WalkthroughsActions';
import { LogOutAuthAction } from "../Features/Auth/Actions/AuthActions";

// Images
import logoLogout from "../assets/logout.svg";
import logoUser from "../assets/user.svg";


const Layout = ({ tabs, logo, isEmbedded }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const appClass = isEmbedded ? "Embedded-App" : "App";

  const handleLogout = () => {
    dispatch(LogOutAuthAction())
      .then(() => {
        navigate("/login", { replace: true });
        if (document.fullscreenElement) document.exitFullscreen();
        window.location.reload(true);
        return false;
      });
  };


  return (
    <main className={appClass}>
      {/* shows inactivity */}
      <IdleTimeOutModal handleLogout={handleLogout} />
      {
        !isEmbedded &&
        <NavBar
          tabs={tabs}
          logo={logo}
          logoLogout={logoLogout}
          logout={handleLogout}
          logoUser={logoUser}
        />
      }
      <Outlet />
    </main>
  );
};

export default Layout;
