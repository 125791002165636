import { createSlice } from "@reduxjs/toolkit";
import {
  RegisterAuthAction,
  LoginAuthAction,
  LogOutAuthAction,
  getAuthUser,
  getUser,
  getUserAudit,
  fetchOrganisationsByUser,
  fetchOrganisationByUser,
  setOrganisationByUser,
  verifyEmail,
  verifyUsername,
  getCountries
} from "../Actions/AuthActions";

const initialState = {
  auth: false,
  userLoaded: false,
  audit: sessionStorage.getItem("audit") || null,
  token: sessionStorage.getItem("auth") || null,
  userInfo: JSON.parse(sessionStorage.getItem("user")) || null,
  organisations: [],
  organisation: 0,
  isLoading: true,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    hideError(state, action) {
      return {
        ...state,
        status: "idle",
        error: null,
      };
    },
    setIsLoading(state, action) {
      return {
        ...state,
        isLoading: action.payload,
        status: "idle",
        error: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(LoginAuthAction.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(LoginAuthAction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.auth = true;
        state.userLoaded = true;
        state.token = action.payload;
      })
      .addCase(LoginAuthAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userLoaded = true;
        state.userInfo = action.payload;
      })
      .addCase(getAuthUser.rejected, (state, action) => {
        state.status = "failed";
        state.userLoaded = false;
        state.error = action.payload;
      })
      .addCase(getAuthUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAuthUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userLoaded = true;
        state.userInfo = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = "failed";
        state.userLoaded = false;
        state.error = action.payload;
      })
      .addCase(getUserAudit.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUserAudit.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.audit = action.payload;
      })
      .addCase(getUserAudit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(LogOutAuthAction.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(LogOutAuthAction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.auth = false;
        state.userLoaded = false;
        state.audit = null;
        state.token = null;
        state.userInfo = null;
        state.organisations = [];
        state.organisation = 0;
        state.userLoaded = false;
      })
      .addCase(LogOutAuthAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(RegisterAuthAction.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(RegisterAuthAction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userLoaded = true;
        state.user = null;
      })
      .addCase(RegisterAuthAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchOrganisationsByUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOrganisationsByUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.organisations = action.payload;
      })
      .addCase(fetchOrganisationsByUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchOrganisationByUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOrganisationByUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.organisation = action.payload;
      })
      .addCase(fetchOrganisationByUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(setOrganisationByUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(setOrganisationByUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.organisation = action.payload;
      })
      .addCase(setOrganisationByUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(verifyEmail.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(verifyUsername.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(verifyUsername.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(verifyUsername.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getCountries.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.status = "succeeded";
      });
  },
});

export const auth = (state) => state.auth.auth;
export const userLoaded = (state) => state.auth.userLoaded;
export const userLogged = (state) => state.auth.userInfo;
export const authError = (state) => state.auth.error;
export const getAuthStatus = (state) => state.auth.status;

export const { hideError, setIsLoading } = authSlice.actions;

export default authSlice.reducer;
