/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

/* MUI PACKAGE IMPORTS */
import Chip from "@mui/material/Chip";
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import UpdateIcon from '@mui/icons-material/Update';
import CancelIcon from '@mui/icons-material/Cancel';

/* INTERNAL IMPORTS */
import { LocalDateTime, formatDate } from "../Components/DateTimeFormatter";
import DataTable from "../Components/DataTable";
import SubNavbar from "../Components/SubNavbar";
import Header from "../Components/Header";

import { setIsLoading } from "../Features/Auth/Slices/authSlice";
import { 
  fetchSessionsByUser,
  fetchCandidateSessions,
  fetchCandidateTodaySessions } from "../Features/Sessions/Actions/SessionsActions";
import { userLogged } from "../Features/Auth/Slices/authSlice";

import '../styles/Navbar.css';

const ORGANISATION_LIST = {
  1: 'Chessish',
  2: "VICTVS",
  3: "CIPS",
  4: "IWCF",
  7: "GMC",
  8: "VICTVS DEMO",
  9: "Test",
  10: "ISVPS",
  11: "RCEM",
  12: "NALP"
};


export default function SessionsCandidate({ paragraph, title, tabs }) {
  const dispatch = useDispatch();

  const { isLoading } = useSelector(store => store.auth);
  const user = useSelector(userLogged);
  const initialPagination = {
    pageSize: 20,
    page: 0,
  };

  const [displaySessions, setDisplaySessions] = useState([]);
  const [radioValue, setRadioValue] = useState("1");
  const [paginationModel, setPaginationModel] = useState(initialPagination);

  const mounted = useRef(null);

  const handleChange = (e) => {
    let value = e.currentTarget.value;
    setRadioValue(value);
    dispatch(setIsLoading(true));
  };

  const getOrganisation = orgId => ORGANISATION_LIST[orgId];

  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      valueGetter: (params) => params?.row?.schedule?.title,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      valueGetter: (params) => params?.row?.schedule?.description,
    },
    {
      field: "organisation",
      headerName: "Organisation",
      width: 200,
      valueGetter: (params) => getOrganisation(params?.row?.schedule?.exam?.orgid),
    },
    // {
    //   field: "pass_mark",
    //   headerName: "Pass mark",
    //   width: 100,
    //   valueGetter: (params) => params?.row?.schedule?.exam?.score,
    // },
    {
      field: "starttime",
      headerName: "Start time",
      type: "date",
      flex: 1,
      valueGetter: (params) => params?.row?.schedule?.starttime,
      valueFormatter: ({ value }) => LocalDateTime(value),
    },
    {
      field: "time_limit",
      headerName: "Time limit",
      width: 90,
      valueGetter: (params) =>
        params?.row?.schedule?.exam?.duration?.stringshort,
    },
    {
      field: "status",
      headerName: "Status",
      width: 116,
      valueGetter: (params) => params?.row?.status,
      renderCell: ({ value }) => {
        let status = "";
        let color = "info";
        let icon = <PendingIcon />;
        
        status = value.charAt(0).toUpperCase() + value.slice(1);
        
        switch (status){
          case "Pending":
            color = "info";
            status= "Ready";
            icon = <PendingIcon />;
            break;
          case "Started":
            color = "primary";
            icon = <PlayCircleFilledIcon />;
            break;
          case "Future":
            status = "Upcoming";
            color = "secondary";
            icon = <UpdateIcon />
            break;
          case "Expired":
            color = "error";
            icon = <CancelIcon />;
            break;
          case "Finished":
            color = "success";
            icon = <CheckCircleIcon />;
            break;
          default:
            break;
        };

        return <Chip label={status} color={color} size="small" icon={icon}/>
      },
    },
  ];

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) dispatch(setIsLoading(true));
    return () => mounted.current = false;
  }, []);

  useEffect(() => {
    mounted.current = true;
    if (!isLoading) {
      sessionStorage.removeItem("scheduleStatus");
      sessionStorage.removeItem("sessionStatus");
      sessionStorage.removeItem("pages");
      return;
    };
    if (mounted.current) {
      document.title = "Sessions";
      const date = new Date(Date.now());
      const dateUTC = formatDate(date);
      const userid = user.userid;
      const requestBody = { userId: userid, startDate: dateUTC };

      if (radioValue === "1") {
        dispatch(fetchCandidateTodaySessions(requestBody))
          .then(response => {
            if (response.payload) {
              setDisplaySessions(response.payload.reverse());
            };
            dispatch(setIsLoading(false)); 
          });
      } else if (radioValue === "2") {
        dispatch(fetchCandidateSessions(requestBody))
          .then(response => {
            if (
              response.payload.length > 0 &&
              typeof response.payload[0] === "object"
            )
            setDisplaySessions(response.payload); 
            dispatch(setIsLoading(false)); 
        });
      } else setDisplaySessions([]);
        
    };
    return () => mounted.current = false;
  }, [isLoading, radioValue, user.userid, dispatch]);

  return (
    <Container className="py-4 position-relative">
      <Header paragraph={paragraph} title={title}>
        <SubNavbar
          radioValue={radioValue}
          tabs={tabs}
          handleChange={handleChange}
          className="SubNavbar"
        />
      </Header>
      <DataTable
        columns={columns}
        rows={displaySessions}
        type={"sessions"}
        loading={isLoading}
        radioValue={radioValue}
        getRowId={row => row.esuid}
        setPaginationModel={setPaginationModel}
        paginationModel={paginationModel}
      />
    </Container>
  );
}
