/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Form, Button, InputGroup, Stack } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";

/* MUI PACKAGE IMPORTS */
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tooltip from "@mui/material/Tooltip";

/* INTERNAL IMPORTS */
import Notes from "../Notes";
import FormComponent from "../../Components/Form";
import Modal from "../../Components/Modal";
import SortableList from "./Edit/SortableList";
import EditVideo from "./Edit/Video";
import HotspotEdit from "./Edit/Hotspot";
import HotspotMap from "./Edit/HotspotMap";
import MultiChoice from "./Edit/MultiChoice";
import InlineChoice from "./Edit/InlineChoice";
import Matching from "./Edit/Matching";


import FormBuilder from "../FormComponents/FormBuilder";


import { fetchQuestionText } from "../../Features/AI/Actions/AIActions";

import {
  addQuestionHeaderImage,
  deleteQuestionHeaderImage,
  addQuestionHeaderVideo,
  deleteQuestionHeaderVideo,
  fetchCategoriesByQuestionHeader,
  removeCategoryFromQuestion,
  fetchAllCategories,
  addCategoryToQuestion,
  createCategory,
  deleteCategory,
  updateQuestion,
  deleteQuestionHeader,
  createQuestion,
  deleteQuestion,
  updateQuestionHeader,
} from "../../Features/Questions/Actions/QuestionActions";
import { fetchQuestionsType } from "../../Features/QuestionBank/Actions/QuestionBankActions";
import {
  uploadImg,
  uploadVideo,
} from "../../Features/Uploads/Actions/UploadsActions";
import { openModal } from "../../Features/Errors/Slices/errorSlice";

import { StyledPagination } from "../../styles/StyledComponents";
import { customStyles } from "../../styles/StyledComponents";
import "../../styles/Note.css";

const description = {
  1: "Create a list of options (text or images) and select the correct option.",
  2: "Create the list of text or image options that are needed and tick the correct options.",
  // 3: "Create and save the options in the list in the correct order.",
  4: "Create the question, enclosing a correct answer in parentheses to create a blank space (e.g. {answer}).",
  6: "Create and then save the options in the correct order.",
  7: "Create and save the list of options with the corresponding matching response.",
  9: "Within the question text, mark at least two blank spaces with the correct answers in curly brackets (e.g. {answer one} and  {answer two}).",
  10: "Within the question text, create a blank space with curly brackets (e.g. {}). Then create and save a list of options, ticking the correct option.",
  11: "Type the title of your question, marking at least one blank space with brackets, and then create and save the list of options you want to be displayed and tick the correct option.",
};

function QuestionComponent({
  questionType,
  questionHeader,
  question
}) {
  const [questionComponent, setComponent] = useState(null);
  const mounted = useRef(null);
  const editQuestionClass = (questionType === "freetext") ? null : "Box box";

  useEffect(() => {
    mounted.current = true;
    if (questionComponent !== null) return;
    if (mounted.current) {
      if (
        questionType === "multichoice" ||
        questionType === "singlechoice" ||
        questionType === "true_false"
      ) {
        setComponent(<MultiChoice question={question} />);
      } else if (questionType === "sorting") {
        setComponent(<SortableList question={question} />);
      } else if (questionType === "matching") {
        setComponent(<Matching question={question} />);
      } else if (questionType === "video") {
        setComponent(<EditVideo question={question} />);
      } else if (
        questionType === "dropdownchoice" ||
        questionType === "draganddropchoice"
      ) {
        setComponent(<InlineChoice question={question} />);
      } else if (questionType === "hotspot") {
        setComponent(
          <HotspotEdit
            question={question}
            questionHeader={questionHeader}
          />
        );
      } else if (questionType === "hotspot-map") {
        setComponent(<HotspotMap question={question} />);
      }
    }
    return () => (mounted.current = false);
  }, [questionType, questionComponent, question]);



  return (
    <div className={editQuestionClass}>
      {
        question?.qtype?.typename !== "freetext" &&
        <h2 className="fw-bold">Options</h2>
      }
      <p className="text-muted">{description[question?.qtype?.tid]}</p>
      {
        questionType === "freetext" ?
          null :
          <>
            <h3 className="fw-bold mt-4 mb-0 fs-5">Options Preview</h3>
            <p className="text-muted">View the previously created question options below</p>
            {
              (questionType === "fillblanks" || 
                questionType === "dropblanks") &&
                question.question.match(/\{(.*?)\}/g).length > 0 &&
                <ol>
                  {
                    question.question
                      .match(/\{(.*?)\}/g).map(blank => <li>{blank.slice(1, -1)}</li>)
                  }
                </ol>
            }
          </>
      }
      {questionComponent}

    </div>
  );

};

export default function EditQuestion() {

  

  const {
    questionHeader,
    questions,
    cats,
    catsByQuestion } = useSelector(store => store.question);
  const { questionTypes } = useSelector(store => store.questions);

  const [showModal, setShowModal] = useState(false);
  const [showCat, setShowCat] = useState(false);
  const [button, setButton] = useState("");
  const [msg, setMsg] = useState("");
  const [action, setAction] = useState(null);

  const [isFormVisible, setFormVisible] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [fileNum, setFileNum] = useState(null);

  const [page, setPage] = useState(1);
  const [url, setUrl] = useState("");
  const [urlVideo, setUrlVideo] = useState("");
  const [newFile, setNewFile] = useState({});
  const [qid, setQid] = useState(null);
  const [catid, setCatid] = useState(null);
  const [formDataHeader, updateFormDataHeader] = useState(questionHeader);
  const [formData, updateFormData] = useState({ question: "", score: 0 });

  const canvasRef = useRef(null);
  const mounted = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();
  const qhid = parseInt(id);

  const disclaimerMessage = `'Fill in the blanks', 'Drop down multiple choice' and 'Drag and drop multiple choice' questionsmust have at least one blank with the correct answer,and in the case of 'Drop in the blanks' at least two.\nExample: '{London} is the capital city of England'.`;
  
  const formFieldsArray = [
    {
      fieldCategory: "dropdown",
      id: "question-type-field",
      label: "Question Type",
      message: "",
      value: "",
      options: questionTypes,
      type: "text",
      isRequired: true,
      invalidFeedback: "This is not Great"
    },
    {
      fieldCategory: "textArea",
      id: "question-text-field",
      label: "Question Text",
      message: "",
      value: "",
      rows: 3,
      type: "text",
      isRequired: true,
      validFeedback: "Great"
    },
    {
      fieldCategory: "input",
      id: "score-field",
      label: "Score",
      message: "",
      value: "",
      type: "number",
      isRequired: true,
      validFeedback: "Great"
    },
  ];

  const handleChange = (e, value) => {
    updateFormData(questions[value - 2]);
    setPage(value);
  };

  const handleNewCat = (newValue, actionMeta) => {
    const { action } = actionMeta;
    if (action === "create-option") {
      const { option } = actionMeta;
      const { label } = option;
      dispatch(createCategory({ qhid, catname: label }));
    } else if (action === "select-option") {
      const { option } = actionMeta;
      const { value } = option;
      dispatch(addCategoryToQuestion({ qhid, catid: value }));
    } else if (action === "remove-value") {
      const { removedValue } = actionMeta;
      const { value } = removedValue;
      setCatid(value);
      dispatch(removeCategoryFromQuestion({ qhid, catid: value }));
      setShowCat(true);
      setMsg(
        "Would you like to remove this category entirely, not only from this question?"
      );
      setButton("Delete");
    }
  };

  const handleAIcall = () => { fetchQuestionText() }

  const handleMouseEnter = (i) => {
    setVisible(true);
    setFileNum(i);
  };

  const handleMouseLeave = (e) => {
    setVisible(false);
    setFileNum(null);
  };

  const deleteCatHandler = (e) => {
    setShowCat(false);
    dispatch(deleteCategory(catid));
  };

  const handleChangeHeaderInput = (e) => {
    const { admintitle, qtext } = formDataHeader;
    updateFormDataHeader({
      admintitle: parse(admintitle),
      qtext,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeInput = (e) => {
    const { question, score, qid } = formData;
    updateFormData({
      question,
      score,
      qid,
      // Trimming any whitespace
      [e.target.name]: e.target.value,
    });
  };

  const updateQuestionHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { qid } = formData;
    dispatch(updateQuestion({ qid, qhid, formData }));
  };

  const updateQuestionHeaderHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(updateQuestionHeader({ qhid, formDataHeader }));
  };

  const deleteQuestionHeaderHandler = (e) => {
    if (mounted.current) dispatch(deleteQuestionHeader({ qhid, navigate }));
  };

  const deleteQuestionHandler = (e) => {
    if (mounted.current) {
      dispatch(deleteQuestion({ qid, qhid }));
      setShowModal(false);
      setPage(1);
    }
  };

  const addQuestionHandler = (data) => {
    if (mounted.current) {
      const requestBody = {
        qhid,
        qtype: parseInt(data['question-type-field']),
        question: data['question-text-field'],
        score: data['score-field'],
      };

      dispatch(createQuestion({ ...requestBody, qhid }));
      setFormVisible(false);
      setPage(questions.length + 2);
      updateFormData(requestBody);
    }
  };

  const uploadHandler = (e) => {
    // Allowing file type
    const fileObj = e.target.files && e.target.files[0];
    if (!fileObj) return;
    
    var allowedImgExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    var allowedVideoExtensions = /(\.mpg|\.mpeg|\.mp4|\.mov|\.ts)$/i;
    const fileSize = fileObj.size / 1024 / 1024; // in MiB
    if (fileSize > 120) {
      dispatch(
        openModal({
          message: "File size exceeds 120 MiB",
          severity: "error",
        })
      );
      setUrlVideo("");
      setUrl("");
      // 👇️ reset file input
      e.target.value = null;
    } else {
      const newFile = URL.createObjectURL(fileObj);
      let reader = new FileReader();
      reader.onload = function () {
        var base64data = reader.result;
        setNewFile(() => {
          return {
            url: newFile,
            filedesc: fileObj.name,
            filename: fileObj.name,
            filecontents: base64data,
            // filecontents: fileObj,
          };
        });
        if (allowedImgExtensions.exec(fileObj.name)) setUrl(newFile);
        else if (allowedVideoExtensions.exec(fileObj.name)) setUrlVideo(newFile);
      };
      reader.readAsDataURL(fileObj);
      reader.onerror = function (error) {
        dispatch(
          openModal({
            message: error,
            severity: "error",
          })
        );
      };
    }
  };

  const handleImageVideoUpload = () => {
    // Allowing file type
    var allowedImgExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    var allowedVideoExtensions =
      /(\.mpg|\.mpeg|\.mp4|\.mov|\.ts)$/i;

    if (allowedImgExtensions.exec(newFile.filename)) {
      dispatch(uploadImg(newFile))
        .then(response => {
          const imid = Number(response.payload);
          if (imid) {
            dispatch(addQuestionHeaderImage({ qhid, imid }))
              .then(() => setUrl(""));
          }
        });
    } else if (allowedVideoExtensions.exec(newFile.filename)) {
      dispatch(uploadVideo(newFile))
        .then(response => {
          const vid = Number(response.payload);
          if (vid) {
            dispatch(addQuestionHeaderVideo({ qhid, vid }))
              .then(() => setUrlVideo(""));
          }
        });
    }
  };

  const handleDelete = () => {
    setShowModal(true);
    setMsg(`Are you sure you want remove the question header 
    "${questionHeader.admintitle}"? This is an irreversible action.`);
    setButton("Delete");
    setAction(1);
  };

  useEffect(() => {
    mounted.current = true;
    if (questionTypes.length > 0) return;
    if (mounted.current) dispatch(fetchQuestionsType());
    return () => mounted.current = false;
  }, [dispatch, questionTypes]);

  useEffect(() => {
    mounted.current = true;
    if (cats.length > 0) return;
    if (mounted.current) dispatch(fetchAllCategories());
    return () => mounted.current = false;
  }, [dispatch, cats]);

  useEffect(() => {
    mounted.current = true;
    if (catsByQuestion.length > 0) return;
    if (mounted.current) dispatch(fetchCategoriesByQuestionHeader(qhid));
    return () => (mounted.current = false);
  }, [dispatch, catsByQuestion, qhid]);


  return (
    <div className="my-4">
      <div className="mb-4 d-flex justify-content-center align-items-center">
        <StyledPagination
          count={questions.length + 1}
          page={page}
          onChange={handleChange}
        />
        <Tooltip title="Add question">
          <IconButton
            aria-label="add question"
            onClick={() => {
              setFormVisible(true);
              setMsg("Select type of question");
              setButton("Create");
            }}
          >
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      </div>
      {page === 1 && (
        // Render the question header cards
        <Form id="question-form" onSubmit={updateQuestionHeaderHandler}>
          <div className="Box background-secondary my-3 box">
            <Form.Group className="my-3" controlId="admintitle">
              <h2 className="fw-bold">Question Reference</h2>
              <p className="text-muted mb-4">Add information about the question group container</p>
              <Form.Label>Question Reference Title</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={
                  formDataHeader.admintitle ?
                    parse(formDataHeader.admintitle) :
                    ""
                }
                name="admintitle"
                onChange={handleChangeHeaderInput}
              />
            </Form.Group>
            <Form.Group className="my-3" controlId="qtext">
              <Form.Label>Question Reference Text (optional)</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={formDataHeader.qtext}
                name="qtext"
                onChange={handleChangeHeaderInput}
              />
            </Form.Group>

            {
              questionHeader.imagecount > 0 &&
              <Form.Group className="my-3" controlId="categories">
                <Form.Label>Question Header Image(s)</Form.Label>
                {questionHeader.images.map((img, i) => {
                  return (
                    <div
                      key={i}
                      onMouseEnter={() => handleMouseEnter(`img_${i}`)}
                      onMouseLeave={() => handleMouseLeave(`img_${i}`)}
                      className="row mb-4 d-flex justify-content-center"
                    >
                      <img
                        alt={`Img_${img.imid}`}
                        style={{
                          objectFit: "cover",
                          width: "300px",
                        }}
                        src={img.direct_url}
                        id={img.imid}
                        key={img.imid}
                      />
                      <Stack
                        direction="horizontal"
                        gap={3}
                        className={
                          isVisible &&
                            fileNum === `img_${i}`
                            ? "shown"
                            : "hidden"
                        }
                      >
                        <Button
                          variant="dark"
                          size="sm"
                          onClick={(e) => {
                            dispatch(
                              deleteQuestionHeaderImage({
                                qhid,
                                imid: img.imid,
                              })
                            );
                          }}
                        >
                          Delete image
                        </Button>
                      </Stack>
                    </div>
                  );
                })}
              </Form.Group>
            }
            {
              questionHeader.videocount > 0 &&
              <Form.Group className="my-3" controlId="categories">
                <Form.Label>Videos</Form.Label>
                {
                  questionHeader.videos.map((video, i) => {
                    return (
                      <div
                        key={i}
                        onMouseEnter={(e) => handleMouseEnter(`video_${i}`)}
                        onMouseLeave={(e) => handleMouseLeave(`video_${i}`)}
                        className="row"
                      >
                        <video
                          style={{
                            objectFit: "cover",
                            width: "500px",
                          }}
                          alt={`Video_${id}`}
                          controls
                        >
                          <source src={video.direct_url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <Stack
                          direction="horizontal"
                          gap={3}
                          className={
                            isVisible &&
                              fileNum === `video_${i}`
                              ? "shown"
                              : "hidden"
                          }
                        >
                          <Button
                            variant="dark"
                            size="sm"
                            onClick={() => {
                              dispatch(
                                deleteQuestionHeaderVideo({
                                  qhid,
                                  vid: video.vid,
                                })
                              );
                            }}
                          >
                            Delete video
                          </Button>
                        </Stack>
                      </div>
                    );
                  })}
              </Form.Group>
            }
            <Form.Group className="my-3" controlId="qimage">

              {
                url &&
                <div>
                  <img
                    width="auto"
                    height="200"
                    src={url}
                    alt="Question header"
                  />
                </div>
              }
              {
                urlVideo &&
                <video width="auto" height="300" controls>
                  <source src={urlVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              }

              <Form.Label>Upload Image or Video (optional)</Form.Label>
              <InputGroup>
                <Form.Control type="file" multiple onChange={uploadHandler} />
                <Button
                  variant="dark"
                  onClick={handleImageVideoUpload}
                >
                  Upload
                </Button>
              </InputGroup>
              <Form.Text>Upload image or video files for the question header</Form.Text>
            </Form.Group>

            <Form.Group className="my-3" controlId="categories">
              <Form.Label id="categories-select">Question Reference Categories</Form.Label>

              <CreatableSelect
                required
                styles={customStyles}
                closeMenuOnSelect={false}
                isMulti
                options={cats}
                defaultValue={catsByQuestion}
                onChange={handleNewCat}
                aria-labelledby="categories-select"
              />
              {
                catsByQuestion.length === 0 &&
                <Form.Text>Type to create a new category or choose a previous category from the dropdown list</Form.Text>
              }
            </Form.Group>

            <div className="d-flex justify-content-between mt-4">
              <Button
                className="me-2"
                variant="dark"
                type="submit"
              >
                Update question reference
              </Button>
              <Button
                variant="dark"
                onClick={handleDelete}
              >
                Delete question reference
              </Button>
            </div>
          </div>
        </Form>
      )}
      {/* Render Questions belonging to the question header */}
      {
        questions.map((question, i) => {
          return (
            page === i + 2 &&
            <div key={i}>
              <Form
                id="formEditQuestion"
                key={i}
                className="Box background-secondary my-2 box"
              >
                <Form.Group className="my-3" controlId="title">
                  <div className="mb-4">
                    <h2 className="fw-bold">Question</h2>
                    <p className="fst-italic m-0 text-muted">{question?.qtype?.typedesc}</p>
                  </div>
                  <Form.Label>Question Text</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="question"
                    value={formData.question}
                    onChange={handleChangeInput}
                  />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label id="Score">Score</Form.Label>
                  <Form.Control
                    aria-labelledby="Score"
                    type="number"
                    value={formData.score}
                    name="score"
                    onChange={handleChangeInput}
                  />
                </Form.Group>
                <div className="my-3 mt-4 d-flex justify-content-between">
                  <Button
                    className="me-2"
                    variant="dark"
                    onClick={updateQuestionHandler}
                  >
                    Update question
                  </Button>
                  <Button
                    variant="dark"
                    onClick={() => {
                      setQid(question.qid);
                      setShowModal(true);
                      setMsg(
                        ```Are you sure you want to remove the question "${question.question}" 
                        from the question header? This is an irreversible action.```
                      );
                      setButton("Delete");
                      setAction(2);
                    }}
                  >
                    Delete question
                  </Button>
                </div>
              </Form>
              <div className="mb-2">
                <QuestionComponent
                  questionType={question?.qtype?.typename}
                  questionHeader={questionHeader}
                  question={question}
                  canvasRef={canvasRef}
                />
              </div>
              <div className="mb-3">
                <Notes id={question.qid} type="questions" title="Question Notes" />
              </div>
            </div>
          )
        })}
      <Modal
        setShowModal={setShowModal}
        show={showModal}
        handleSubmit={action === 1 ?
          deleteQuestionHeaderHandler :
          deleteQuestionHandler
        }
        msg={msg}
        button={button}
      />
      <Modal
        setShowModal={setShowCat}
        show={showCat}
        handleSubmit={deleteCatHandler}
        msg={msg}
        button={button}
      />
      {
        questionTypes.length > 0 &&
        // (<FormComponent
        //   title="Add question"
        //   selects={[
        //     {
        //       label: "Question type",
        //       type: "text",
        //       id: "qtype",
        //       value: "",
        //       options: questionTypes,
        //       required: true,
        //       order: "first",
        //     },
        //   ]}
        //   inputs={[
        //     {
        //       label: "Question",
        //       type: "text",
        //       id: "question",
        //       value: "",
        //       required: true,
        //       message: disclaimerMessage,
        //     },
        //     {
        //       label: "Score",
        //       type: "number",
        //       id: "score",
        //       value: 0,
        //       required: true,
        //     },
        //   ]}
        //   multipleSelects={[]}
        //   multipleValues={[]}
        //   button="Add question"
        //   logo={""}
        //   logoClass="plus position-fixed"
        //   action={addQuestionHandler}
        //   isFormVisible={isFormVisible}
        //   setFormVisible={setFormVisible}
        //   controlId="form-modal"
        // />)
        <FormBuilder
          isFormVisible={isFormVisible}
          setIsFormVisible={setFormVisible}
          formTitle={"Create Question"}
          formAction={addQuestionHandler}
          fieldData={formFieldsArray}
        />
      }
    </div>
  );
}
