/* REACT PACKAGE IMPORTS */
import { useState } from "react";
import { Form } from "react-bootstrap"

/* INTERNAL IMPORTS */
import AssistantButton from "./AssistantButton";



export default function TextAreaField({ textArea }) {
  const [outputFieldValue, setOutputFieldValue] = useState(textArea.value);

  const handleChange = (e) => setOutputFieldValue(e.target.value);

  const fieldLabel = (textArea.isRequired) ?
    <Form.Label className="fw-bold m-0">{textArea.label}
      <span className="asterisk-red"> *</span>
    </Form.Label> :
    <Form.Label className="fw-bold m-0">{textArea.label}</Form.Label>;


  return (
    <Form.Group
      className={"mb-4"}
      key={textArea.id}
      controlId={textArea.id}
    >
      <div className="d-flex flex-column">
        {fieldLabel}
        {
          textArea.message &&
          <Form.Text className="text-muted mt-0 mb-1">{textArea.message}</Form.Text>
        }
      </div>
      <Form.Control
        data-cy={textArea.label}
        type={textArea.type}
        as="textarea"
        rows={textArea.rows}
        required={textArea.isRequired}
        value={outputFieldValue}
        onChange={handleChange}
        name={textArea.id}
      />
      {/*<AssistantButton setOutputFieldValue={setOutputFieldValue}/>*/}
      {
        textArea.invalidFeedback &&
        <Form.Control.Feedback type="invalid">
          {textArea.invalidFeedback}
        </Form.Control.Feedback>
      }
      {
        textArea.validFeedback &&
        <Form.Control.Feedback type="valid">
          {textArea.validFeeback}
        </Form.Control.Feedback>
      }
    </Form.Group>
  )
};