import React, { useEffect, useState, useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { formatterDropInTheBlanks, flattenArr } from "../../utils/tools.js";
import { Form, InputGroup, Button, Modal } from "react-bootstrap";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TableRowsIcon from "@mui/icons-material/TableRows";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import {
  saveMark,
  fetchMarks,
  fetchSessionsBySchedule,
  fetchAnswersCSV
} from "../../Features/Sessions/Actions/SessionsActions";

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

export default function Marking({ esId }) {
  const { questions } = useSelector((store) => store.assessments);
  const { answers, marks } = useSelector((store) => store.sessions);
  const [modalShow, setModalShow] = useState(false);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [show, setShow] = useState(false);
  const [question, setQuestion] = useState({});
  const [questionId, setQuestionId] = useState(null);
  const [selected, setSelected] = useState(false);
  const [mark, setMark] = useState(0);
  const mounted = useRef(null);
  const textRef = useRef(null);
  const resultsRef = useRef(null);

  const dispatch = useDispatch();

  let { id } = useParams();
  const schId = parseInt(id);

  const printFreeText = () => {
    const pdf = new jsPDF();
    pdf.text(textRef.current.innerText, 10, 10);
    pdf.save("download.pdf");
  };

  const printResults = () => {
    const pdf = new jsPDF("landscape", "pt", "a4");

    html2canvas(resultsRef.current).then((canvas) => {
      const img = canvas.toDataURL("image/png");
      const imgProperties = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  const exportExcel = () => {
    const questionsArr = questions.map((section) => section.questions);
    var mergedQuestions = [].concat
      .apply([], questionsArr)
      .map((question) => question.questions);
    var totalQuestions = flattenArr(mergedQuestions);

    const csvData = totalQuestions.map((item, i) => {
      const { options, qtype, question, score, qid } = item;
      const currAnswers = answers.filter((answer) => answer.qid == qid)[0];
      const { answer, answered, marked, marks } = currAnswers;

      const correct_answers = options
        ? options
            .filter((option) => option.iscorrect)
            .map((option) => option.opttext)
            .join(", ")
        : question.substring(question.indexOf("{") + 1, question.indexOf("}"));
      const newAnswer = options
        ? options
            .filter((option) => answer.includes(option.qoid))
            .map((option) => option.opttext)
            .join(", ")
        : answer.join(", ");

      const newQuestion = {
        Id: qid,
        Question: question,
        Type: qtype.typedesc,
        Answer: newAnswer,
        "Correct answer": correct_answers,
        Marked: marked,
        Marks: marks,
        Score: score,
        Answered: answered,
      };
      return newQuestion;
    });

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, "results.xlsx");
  };

  const saveMarkHandler = () => {
    const data = {
      qid: questionId,
      mark: mark,
      notes: note,
    };
    dispatch(saveMark({ esId, data }));
    dispatch(fetchSessionsBySchedule(schId));
    setModalShow(false);
    setQuestionId(null);
    setSelected(true);
  };

  const handleMarkQuestion = (e) => {
    setModalShow(true);
    setQuestion(question);
    setQuestionId(question.qid);
    setSelected(true);
    setMark(() => answers.filter(answer => answer.qid == question.qid)[0]?.marks);
    setNotes(() => {
      return marks.length > 0 ?
        marks.map(mark => mark.marknotes) :
        [];
    });
  };

  const handleDownloadCSV = () => {
    dispatch(fetchAnswersCSV(esId))
      .then(({ payload: data }) => {
        // Create a Blob with the CSV data and type
        const blob = new Blob([data], { type: 'text/csv' });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');

        // Set the URL and download attribute of the anchor tag
        a.href = url;
        a.download = esId + '-answers.csv';

        // Trigger the download by clicking the anchor tag
        a.click();
      });
  };

  useEffect(() => {
    mounted.current = true;
    if (marks.length > 0) return;
    if (mounted.current) dispatch(fetchMarks(esId));
    return () => (mounted.current = false);
  }, [marks, esId]);


  return (
    <TableContainer className="box mb-3" ref={resultsRef}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="fw-bold">Question</TableCell>
            <TableCell className="fw-bold">Type</TableCell>
            <TableCell className="fw-bold" align="left">
              Options
            </TableCell>
            <TableCell className="fw-bold" align="left">
              Correct Answer
            </TableCell>
            <TableCell className="fw-bold" align="center">
              Marked
            </TableCell>
            <TableCell className="fw-bold" align="center">
              Score
            </TableCell>
            <TableCell className="fw-bold" align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions.length > 0 &&
            questions.map((section, index) => {
              return (
                section.questions.length > 0 &&
                section.questions.map((questionHeader, index) => {
                  return questionHeader.questions.map((question, i) => {
                    return (
                      <React.Fragment key={i}>
                        <TableRow
                          key={i}
                          className={
                            questionId === question.qid && selected
                              ? "page_tagged"
                              : ""
                          }
                        >
                          <TableCell component="th" scope="row">
                            {question.qtype.typename === "fillblanks" ||
                            question.qtype.typename === "draganddropchoice"
                              ? question.question.replace(/\{(.*?)\}/, "...")
                              : question.question}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {question.qtype.typedesc}
                          </TableCell>
                          {question.qtype.typename === "freetext" ? (
                            <TableCell align="center" colSpan={2}>
                              <Tooltip title="Read answer">
                                <IconButton
                                  aria-label="Read answer"
                                  onClick={(e) => {
                                    setShow(!show);
                                    setQuestionId(question.qid);
                                  }}
                                >
                                  <OpenInFullIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          ) : (
                            <React.Fragment>
                              <TableCell align="left">
                                { (question.qtype.typename === "singlechoice" ||
                                  question.qtype.typename === "multichoice" ||
                                  question.qtype.typename === "dropdownchoice" ||
                                  question.qtype.typename === "draganddropchoice") &&
                                  question.options &&
                                  question.options.map((option, i) => {
                                    return (
                                      <li
                                        className="list-group-item d-flex justify-content-between"
                                        key={i}
                                      >
                                        {option.optimage ? (
                                          <img
                                            key={parseInt(option.optimage.imid)}
                                            style={{
                                              width: "70px",
                                              objectFit: "cover",
                                            }}
                                            src={option.optimage.direct_url}
                                            alt="Question"
                                          />
                                        ) : (
                                          option.opttext
                                        )}
                                        {
                                        answers
                                          .filter(answer => answer.qid == question.qid)[0]?.answer &&
                                        answers
                                          .filter((answer) => {
                                            return answer.qid == question.qid;
                                          })[0]
                                          .answer.includes(option.qoid) ? (
                                          <CheckIcon
                                            fontSize="small"
                                            className="right"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </li>
                                    );
                                  })}
                                {question.qtype.typename === "sorting" &&
                                  answers
                                    .filter((answer) => {
                                      return answer.qid == question.qid;
                                    })[0]
                                    .answer.map((answer, i) => {
                                      return (
                                        <li
                                          className="list-group-item d-flex justify-content-between"
                                          key={i}
                                        >
                                          {question.options
                                            .filter(
                                              (option) => answer === option.qoid
                                            )
                                            .map((option) => option.opttext)}
                                        </li>
                                      );
                                    })}

                                {question.qtype.typename === "matching" &&
                                  answers.filter((answer) => {
                                    return answer.qid == question.qid;
                                  }).length > 0 &&
                                  answers
                                    .filter((answer) => {
                                      return answer.qid == question.qid;
                                    })[0]
                                    .answer.map((answer, i) => {
                                      return (
                                        <li
                                          className="list-group-item text-start d-flex justify-content-between"
                                          key={i}
                                        >
                                          {question.options
                                            .filter(
                                              (option) =>
                                                answer.optid === option.qoid
                                            )
                                            .map((option) => option.opttext)}
                                          {" - "}
                                          {answer.answer}
                                        </li>
                                      );
                                    })}

                                {question.qtype.typename === "fillblanks" &&
                                  answers
                                    .filter((answer) => {
                                      return answer.qid == question.qid;
                                    })[0]
                                    .answer.map((answer, i) => {
                                      return answer;
                                    })}

                                {question.qtype.typename === "dropblanks" &&
                                  answers.filter((answer) => {
                                    return answer.qid == question.qid;
                                  }).length > 0 &&
                                  formatterDropInTheBlanks(
                                    question.question,
                                    answers.filter((answer) => {
                                      return answer.qid == question.qid;
                                    })[0].answer
                                  )}
                              </TableCell>
                              <TableCell align="left">
                                {
                                  (question.qtype.typename === "singlechoice" ||
                                  question.qtype.typename === "multichoice" ||
                                  question.qtype.typename === "dropdownchoice" ||
                                  question.qtype.typename === "draganddropchoice") &&
                                  question.options &&
                                  question.options.map((option, i) => {
                                    if (option.iscorrect) {
                                      return (
                                        <li
                                          className="list-group-item d-flex justify-content-between"
                                          key={i}
                                        >
                                          {
                                            option.optimage ?
                                              <img
                                                key={parseInt(option.optimage.imid)}
                                                style={{
                                                  width: "70px",
                                                  objectFit: "cover",
                                                }}
                                                src={option.optimage.direct_url}
                                                alt={`Image_${option.optimage.imid}`}
                                              /> :
                                              option.opttext
                                          }
                                        </li>
                                    )}})}
                                {question.qtype.typename === "matching" &&
                                  question.options.filter((option) => {
                                    return option.qid == question.qid;
                                  }).length > 0 &&
                                  question.options.map((option, i) => {
                                    return (
                                      <li
                                        className="list-group-item text-start d-flex justify-content-between"
                                        key={i}
                                      >
                                        {option.opttext}
                                        {" - "}
                                        {option.optanswer}
                                      </li>
                                    );
                                  })}

                                {question.qtype.typename === "sorting" &&
                                  question.options.map((option, i) => {
                                    return (
                                      <li
                                        className="list-group-item d-flex justify-content-between"
                                        key={i}
                                      >
                                        {option.opttext}
                                      </li>
                                    );
                                  })}
                                {question.qtype.typename === "fillblanks" &&
                                  question.question.substring(
                                    question.question.indexOf("{") + 1,
                                    question.question.indexOf("}")
                                  )}

                                {question.qtype.typename === "dropblanks" &&
                                  formatterDropInTheBlanks(
                                    question.question,
                                    question.blanks
                                  )}
                              </TableCell>
                            </React.Fragment>
                          )}

                          <TableCell align="center">
                            {
                              answers.filter((answer) =>  answer.qid == question.qid)[0]?.marked ?
                                <p>Yes</p> :
                                <p>Pending</p>
                            }
                          </TableCell>
                          <TableCell align="center">
                            {
                              answers.filter((answer) => {
                                return answer.qid == question.qid;
                              })[0]?.marks
                            }
                            /{question.score}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Mark question">
                              <IconButton
                                aria-label="Mark question"
                                onClick={(e) => handleMarkQuestion(e)}
                              >
                                <ChatBubbleOutlineIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                        {question.qtype.typename === "freetext" && (
                          <TableRow>
                            <TableCell
                              style={{ padding: 0, paddingTop: 0 }}
                              colSpan={7}
                            >
                              <Collapse
                                in={show && question.qid == questionId}
                                timeout="auto"
                                unmountOnExit
                                className="Box background-secondary p-0"
                              >
                                <div ref={textRef} className="m-3">
                                  {answers.filter((answer) => {
                                    return answer.qid == questionId;
                                  }).length > 0 &&
                                    answers
                                      .filter((answer) => {
                                        return answer.qid == questionId;
                                      })[0]
                                      .answer.map((answer, i) => {
                                        return answer;
                                      })}
                                </div>
                                <div className="text-end">
                                  <Tooltip title="Download answer">
                                    <IconButton
                                      aria-label="Download answer"
                                      onClick={(e) => printFreeText()}
                                    >
                                      <FileDownloadIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  });
                })
              );
            })}
        </TableBody>
      </Table>
      <div className="text-end">
        <Tooltip title="Export pdf">
          <IconButton aria-label="Export pdf" onClick={printResults}>
            <FileDownloadIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Export excel">
          <IconButton aria-label="Export excel" onClick={exportExcel}>
            <TableRowsIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download CSV">
          <IconButton aria-label="Download CSV" onClick={handleDownloadCSV}>
            <FileDownloadIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <Modal
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Form.Group className="mb-2" controlId="mark">
            <Form.Label>Marks</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                value={mark}
                onChange={(e) => setMark(e.target.value)}
                max={question.score}
              />
              <InputGroup.Text>/{question.score}</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="" controlId="mark">
            <Form.Label>Notes</Form.Label>
            <InputGroup>
              <Form.Control
                as="textarea"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </InputGroup>
            <ul>
              {notes.map((note, i) => note !== "auto" && <li key={i}>{note}</li>)}
            </ul>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            onClick={() => {
              setModalShow(false);
              setQuestionId(null);
              setSelected(false);
            }}
          >
            Close
          </Button>
          <Button variant="dark" onClick={saveMarkHandler}>
            Mark
          </Button>
        </Modal.Footer>
      </Modal>
    </TableContainer>
  );
}
