/* REACT PACKAGE IMPORTS */
import { useRef, useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

/* MUI PACKAGE IMPORTS */
import { Divider } from "@mui/material";

/* INTERNAL IMPORTS */
import { forgotPassword } from "../Features/Auth/Actions/AuthActions";
import { openModal } from "../Features/Errors/Slices/errorSlice";
import "../styles/Login.css";
import ForgotPasswordIcon from '../assets/authentication/forgotPasswordIcon.png';


export default function ForgotPassword({ logo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailInput, setEmailInput] = useState("");
  const [usernameInput, setUsernameInput] = useState("");

  const emailRef = useRef();
  const usernameRef = useRef();

  useEffect(() => emailRef.current.focus(), []);
  useEffect(() => removeValidationClasses(), [emailInput, usernameInput]);

  const removeValidationClasses = () => {
    emailRef.current.classList.remove('is-valid');
    emailRef.current.classList.remove('is-invalid');
    usernameRef.current.classList.remove('is-valid');
    usernameRef.current.classList.remove('is-invalid');
  };

  const validateInput = () => {
    const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailIsValid = emailRegEx.test(emailInput);
    const usernameIsValid = usernameInput.length > 5 && usernameInput.length < 30;

    let validationErrorMessage = "";
    let inputIsValid = true;


    // Case: Email field filled, Username field empty
    if (!emailIsValid && usernameInput === "") {
      validationErrorMessage = `Please enter a valid email address e.g. user@victvs.co.uk`;
      emailRef.current.classList.add('is-invalid');
      inputIsValid = false;
    };

    // Case: Username field filled, Email field empty
    if (!usernameIsValid && emailInput === "") {
      validationErrorMessage = `Please enter a valid username`;
      usernameRef.current.classList.add('is-invalid');
      inputIsValid = false;
    };

    // Case: Email and Username fields filled
    if (emailInput !== "" && usernameInput !== "") {
      if (!emailIsValid && !usernameIsValid) {
        validationErrorMessage = `Please enter a valid Email address and Username`;
        emailRef.current.classList.add('is-invalid');
        usernameRef.current.classList.add('is-invalid');
        inputIsValid = false;
      }
    };

    // Case: Email and Username fields empty
    if (emailInput === "" && usernameInput === "") {
      validationErrorMessage = `Please enter a valid Email address or Username`;
      emailRef.current.classList.add('is-invalid');
      usernameRef.current.classList.add('is-invalid');
      inputIsValid = false;
    };

    // Return input validity
    if (inputIsValid) {
      return true;
    } else {
      dispatch(
        openModal({
          message: validationErrorMessage,
          severity: "warning",
        })
      );
      return false;
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create suitable request data object if input is valid
    if (validateInput()) {
      let requestObject;
      if (usernameInput === "") {
        requestObject = { email: emailInput };
        emailRef.current.classList.add('is-valid');
      } else if (emailInput === "") {
        requestObject = { username: usernameInput };
        usernameRef.current.classList.add('is-valid');
      } else {
        requestObject = { email: emailInput, username: usernameInput };
        emailRef.current.classList.add('is-valid');
        usernameRef.current.classList.add('is-valid');
      };

      dispatch(forgotPassword(requestObject))
        .then(response => {
          if (response.error) return;
          setTimeout(() => navigate("/login"), 2500)
        });
    };
  };

  return (
    <main className="Login position-relative">
      <div className="Login-container box position-absolute top-50 start-50 translate-middle p-4">
        <div className="d-flex flex-column mx-5 my-3 justify-content-between align-items-center">
          {/* Invisible h1 added for accessibility requirements */}
          <h1 className="visually-hidden">victvs vx forgot password</h1>
          <div className="mb-2">
            <img src={logo} alt="Logo" className="h-32" />
          </div>
          <p className="h6 my-2 text-slate-700">Forgot Password</p>
          <div className="text-center">
            <Form.Text>
              Please enter your email address or username below
              and we will send a new password to you.
            </Form.Text>
          </div>
        </div>

        <Form
          id="form"
          className="mx-5 my-3"
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-4">
            <Form.Label id="email-label">Email</Form.Label>
            <Form.Control
              data-cy="emailField"
              ref={emailRef}
              aria-labelledby="email-label"
              name="email"
              type="email"
              value={emailInput}
              onInput={event => setEmailInput(event.target.value)}
            />
            <Divider className="my-3 mt-4">OR</Divider>
            <Form.Label id="username-label">Username</Form.Label>
            <Form.Control
              data-cy="usernameField"
              ref={usernameRef}
              aria-labelledby="username-label"
              name="username"
              type="text"
              value={usernameInput}
              onInput={event => setUsernameInput(event.target.value)}
            />

          </Form.Group>
          <Button
            data-cy="submitButton"
            variant="dark"
            type="submit"
            className="my-3 mb-4 container"
          >
            Submit
          </Button>

          <div className="d-flex flex-column">
            <Form.Text>
              Back to Sign In page?
              <Link
                className="mx-2 text-decoration-none link-font-size"
                onClick={() => navigate(-1)}
              >
                Sign In
              </Link>
            </Form.Text>
          </div>
        </Form>
      </div>
    </main>
  );
}
