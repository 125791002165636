/* REACT PACKAGE IMPORTS */
import { useEffect, useRef, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

/* MUI PACKAGE IMPORTS */
import { ThemeProvider } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";

/* INTERNAL IMPORTS */
import ViewQuestion from "../Questions/ViewQuestion";
import CountdownTimer from "../Timer";
import SimplePagination from "../SimplePagination";
import AssessmentUtilities from "./AssessmentUtilities";
import ScrollDiv from "../ScrollDiv";
import {
  newAnswer,
  tagPage,
  completePage,
  savePages,
} from "../../Features/Sessions/Slices/sessionsSlice";
import { postAnswer, postQuestionFlag, deleteQuestionFlag } from "../../Features/Sessions/Actions/SessionsActions";
import { theme } from "../../styles/StyledComponents";
import '../../styles/Assessment.css';
import { openModal } from "../../Features/Errors/Slices/errorSlice";


export default function SectionlessAssessment({
  questions,
  section,
  sessionExpired,
  page,
  setPage,
  targetDate,
  setSessionExpired,
  userAnswerArray,
  setUserAnswerArray,
  userFlaggedArray
}) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const sessionId = parseInt(id);

  const { pages, sessionStatus } = useSelector(store => store.sessions);

  const [pagesClasses, setPagesClasses] = useState([]);
  const [showPagination, setShowPagination] = useState(false);

  const [markPages, setMarkPages] = useState(true);
  const [isComplete, setIsComplete] = useState(false);

  const mounted = useRef(null);
  const bottomRef = useRef(null);

  /* HANDLER FUNCTIONS */
  const handleTagPage = (question, section, page) => {
    const esId = sessionId;
    const qid = question.questions[0].qid;
    dispatch(tagPage({ qhid: question.qhid, section, page }));
    if (areQuestionsFlagged()[page - 1] === "tagged") dispatch(deleteQuestionFlag({ esId, qid }));
    else dispatch(postQuestionFlag({ esId, qid }));
  };

  const handlePageAndAnswer = (pageNumber) => {
    if (userAnswerArray.length > 0) {
      const questionHeaderId = pages
        .filter(item => item.section === section && item.page === page)
        .map(item => item.qhid)[0];

      const answeredQuestionId = userAnswerArray
        .filter(question => question.qhid === questionHeaderId)
        .map(question => question.qid);

      const answeredQuestion = userAnswerArray.filter(userAnswer => answeredQuestionId[0] === userAnswer.qid);

      if (answeredQuestion.length > 0) {
        console.log(answeredQuestion[0], "this is the answered question");
        const currentAnswerQID = answeredQuestion[0].qid;
        const currentAnswerContent = answeredQuestion[0].answer;
        if (!currentAnswerQID || !currentAnswerContent) {
          setPage(pageNumber);
          return;
        };

        dispatch(completePage({ section, page }));
        dispatch(
          newAnswer({
            qid: currentAnswerQID,
            answer: currentAnswerContent,
          })
        );
        dispatch(postAnswer({ esId: sessionId, answer: answeredQuestion[0] }));
      };
    };
    setPage(pageNumber);
  };

  const handleFinishSection = () => {
    dispatch(openModal({
      message: "Click 'Finish Assessment' to finish or you can still change your answers below",
      severity: "info",
    }));
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  /* HELPER FUNCTIONS */
  // This function creates an array containing ["Tagged", "Completed", "Empty"]
  // Display status of answer completion, flag or uncompleted
  const areQuestionsFlagged = () => {
    const pageStatusArray = pages
      .map(item => {
        if (item.tagged) return "tagged";
        else if (item.completed) return "completed";
        else return "empty";
      });
    return pageStatusArray;
  };

  const scrollToBottom = () => bottomRef.current?.scrollIntoView({ behavior: "smooth" });

  /* HELPER FUNCTIONS */
  useEffect(() => {
    scrollToBottom();
    mounted.current = true;

    if (mounted.current) {
      if (!pages || pages.length === 0) {
        dispatch(savePages());
        return;
      };

      setShowPagination(true);
      let sortedPages = [];
      if (
        markPages &&
        questions.length > 0 &&
        (userAnswerArray.length > 0 || userFlaggedArray.length > 0)) {
        const answeredQIDArray = userAnswerArray.map(answer => answer.qid);
        const answeredQHIDArray = [];
        const flaggedQHIDArray = [];

        questions.forEach(questionHeaders => {
          questionHeaders.questions.forEach(question => {
            const questionsAnswered = question.questioncount === 1 &&
              answeredQIDArray.includes(question.questions[0].qid);
            if (questionsAnswered) answeredQHIDArray.push(question.questions[0].qhid);
            if (userFlaggedArray.includes(question.questions[0].qid)) {
              flaggedQHIDArray.push(question.questions[0].qhid);
            };
          });
        });

        pages.forEach(page => {
          if (flaggedQHIDArray.includes(page.qhid)) {
            dispatch(tagPage({ qhid: page.qhid, section: page.section, page: page.page }));
          };
          if (answeredQHIDArray.includes(page.qhid)) {
            dispatch(completePage({ section: page.section, page: page.page }));
          };
        });

        sortedPages = [...pages]
          .map(page => {
            if (page.tagged) return "tagged";
            else if (page.completed) return "completed";
            else return "empty";
          });

        setMarkPages(false);
      } else {
        sortedPages = [...pages]
          .map(page => {
            if (page.tagged) return "tagged";
            else if (page.completed) return "completed";
            else return "empty";
          });
      };
      setPagesClasses(() => sortedPages);
    };

    return () => mounted.current = false;
  }, [pages]);

  // loop through all answers and send them off to the database
  useEffect(() => {
    userAnswerArray.forEach(userAnswer => {
      console.log(userAnswer, "this is the user answer");
      dispatch(postAnswer({ esId: sessionId, answer: userAnswer }));
    });
  }, [userAnswerArray]);


  return (
    <>
      <Container className="position-relative p-4">
        <div className="my-3">
          <ScrollDiv ref={bottomRef} />
          <CountdownTimer
            targetDate={targetDate}
            sessionExpired={sessionExpired}
            setSessionExpired={setSessionExpired}
            paused={sessionStatus === "paused" ? true : false}
          />
          <hr />
        </div>
        {
          questions[0] !== null ?
            questions[0]?.questions?.map((question, i) => {
              return (page === i + 1) ? (
                <div key={i}>
                  <ViewQuestion
                    questions={question.questions}
                    questionHeader={question}
                    userAnswerArray={userAnswerArray}
                    setUserAnswerArray={setUserAnswerArray}
                    setIsComplete={setIsComplete}
                  />
                  <div className="d-flex mx-4 mb-4 justify-content-between align-content-center">
                    <Button
                      className="default-shadow"
                      variant="dark"
                      onClick={() => handlePageAndAnswer(i)}
                      disabled={i === 0}
                    >
                      Previous Question
                    </Button>
                    <div>
                      <ThemeProvider theme={theme}>
                        <IconButton
                          aria-label="flag"
                          color={
                            areQuestionsFlagged()[page - 1] === "tagged" ?
                              "flag" :
                              "primary"
                          }
                          size="medium"
                          onClick={() => handleTagPage(question, section, page)}
                        >
                          {
                            areQuestionsFlagged()[page - 1] === "tagged" ?
                              <FlagIcon /> :
                              <FlagOutlinedIcon />
                          }
                        </IconButton>
                      </ThemeProvider>
                    </div>
                    {
                      i === questions[0]?.questions.length - 1 || sessionExpired ?
                        <Button
                          className="default-shadow"
                          variant="dark"
                          onClick={() => {
                            handlePageAndAnswer(i + 1);
                            handleFinishSection();
                          }}
                        >
                          Finish Section
                        </Button> :
                        <Button
                          className="default-shadow"
                          variant="dark"
                          onClick={() => handlePageAndAnswer(i + 2)}
                        >
                          Next Question
                        </Button>
                    }
                  </div>
                </div>
              ) :
                null;
            }) :
            null
        }
        {
          questions[0]?.questions?.length > 0 ?
            <SimplePagination
              count={questions[0].questions.length}
              page={page}
              section={section}
              onChange={handlePageAndAnswer}
              pages={pagesClasses}
            /> :
            null
        }
        <AssessmentUtilities />
      </Container>
    </>
  );
};